$timelineDotOffset: -30px;
$timelineDotOffsetMobile: -37px;

main.aboutUsOurHistory {
  .history-timeline .container {
    position: relative;
    display: flex;
    flex-direction: column;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      height: 100%;
      width: 2px;
      background-color: $lightgreen;
      transform: translate(-50%, -50%);

      @media screen and (max-width: breakpoint-max(sm)) {
        left: map_get($spacers, 2);
      }
    }

    .history-timeline__item {
      width: calc(100% - 1.5rem);
      margin-left: auto;

      @media screen and (min-width: breakpoint-min(md)) {
        width: calc(50% - 1.5rem);
        margin-left: 0;
      }

      &:nth-of-type(odd) {
        .item__content {
          &::after {
            left: 0;
            border-right: 10px solid $green;
            transform: translate(-100%, -50%);

            @media screen and (min-width: breakpoint-min(md)) {
              left: auto;
              right: 0;
              border-left: 10px solid $green;
              border-right: none;
              transform: translate(100%, -50%);
            }
          }

          &::before {
            right: auto;
            left: $timelineDotOffsetMobile;

            @media screen and (min-width: breakpoint-min(md)) {
              right: $timelineDotOffset;
              left: auto;
            }
          }
        }

      }

      &:nth-of-type(even) {
        margin-left: auto;

        .item__content {
          &::after {
            left: 0;
            border-right: 10px solid $green;
            transform: translate(-100%, -50%);
          }

          &::before {
            left: $timelineDotOffset;

            @media screen and (max-width: breakpoint-max(sm)) {
              left: $timelineDotOffsetMobile;
            }
          }
        }
      }

      &:last-child {
        .item__content {
          margin-bottom: map_get($spacers, 3);
        }
      }

      .item__content {
        position: relative;
        margin-top: map_get($spacers, 3);
        margin-bottom: map_get($spacers, 5);
        color: $white;
        background-color: $green;
        border-radius: 5px;
        @include box-shadow(8);

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          width: 12px;
          height: 12px;
          border: 2px solid $green;
          border-radius: 50%;
          background-color: $lightgreen;
          transform: translateY(-50%);
          z-index: 1;
        }

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
        }

        img {
          width: calc(100% + #{map_get($spacers, 5)});
          height: auto;
          margin-left: map_get($spacers, -4);
          margin-bottom: map_get($spacers, -4);
          border-radius: 0 0 5px 5px;
        }
      }
    }
  }
}