.featured-card {
  @include box-shadow(1);
  transition: $transition-timing-normal;

  &:hover {
    transform: translate(0, -5px) scale(1.0125);
    @include box-shadow(4);
  }

  .card-header {
    .card-img {
      border-radius: 0;
      width: 100%;
      height: 240px;
      object-fit: cover;
    }
  }

  .card-body {
    .featured-card__heading {
      font-family: $font-family-sans-serif;
    }

    .btn {
      justify-self: end;
      box-shadow: none;
    }
  }
}