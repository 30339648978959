.testimonial {
  .testimonial__body {
    margin-bottom: map_get($spacers, 3);
    padding: map_get($spacers, 3);
    border-radius: 5px;
    background-color: $gray;

    @include box-shadow(1);
    transition: box-shadow $transition-timing-fast;

    &:hover {
      @include box-shadow(2);

    }
  }

  .testimonial__footer {
    .testimonial__avatar {
      width: 40px;
      height: 40px;
      margin-right: map_get($spacers, 3);
      border: 1px solid $gray-400;
      border-radius: 50%;
      background-color: $darkgray;
    }
  }
}