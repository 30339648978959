.banner {
  &--statement {
    width: 100%;
    height: 500px;
    @include box-shadow-inset(6);

    @media screen and (max-width: breakpoint-max(sm)) {
      height: 400px;
    }

    .container {
      position: relative;

      .banner__content-wrapper {
        position: absolute;
        top: 50%;
        left: 100px;
        width: 75%;
        transform: translate(0, -50%);

        @media screen and (max-width: breakpoint-max(md)) {
          left: 50px;
        }

        @media screen and (max-width: breakpoint-max(sm)) {
          position: relative;
          width: 100%;
          left: 0;
        }

        .banner__heading {
          position: relative;
          padding: map_get($spacers, 3);
          font-family: $font-family-serif;
          background-color: transparentize($lightgreen, 0.3);
          font-size: $display4-size;

          &::after, &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: transparentize($lightgreen, 0.3);
          }

          &::before {
            top: 0;
            right: 100%;
          }

          &::after {
            bottom: 0;
            left: 100%;
          }

          @media screen and (max-width: breakpoint-max(sm)) {
            font-size: $h1-font-size;
          }
        }

        .banner__subheading {
          margin-left: map_get($spacers, 3);

          p {
            margin-bottom: map_get($spacers, 1);
            color: $white !important;
            font-weight: 400;
          }
        }

        .banner__link {
          margin-left: map_get($spacers, 3);

          a {
            color: $white !important;
            font-weight: 400;
          }
        }
      }
    }
  }

  &--split-cta {
    width: 100%;

    .banner__ctas {
      .banner__cta {
        width: 50%;
        height: auto;
        padding: map_get($spacers, 2) 0;
        margin-bottom: 2px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1px;
        text-decoration: none;
        transition: $transition-timing-normal;

        .banner__icon {
          height: 20px;
          width: auto;
          fill: $white;
        }

        &:first-of-type {
          background-color: $lightgreen;
          color: $gray-100;
          margin-right: 2px;

          &:hover {
            background-color: darken($lightgreen, 5%);
          }

          .banner__icon {
            height: 20px;
            width: auto;

            * {
              fill: $white;
            }
          }
        }

        &:nth-of-type(2) {
          background-color: $gray-100;
          color: $lightgreen;

          &:hover {
            background-color: darken($gray-100, 5%);
          }

          .banner__icon {
            height: 20px;
            width: auto;

            * {
              fill: $lightgreen !important;
            }
          }
        }
      }
    }

    .banner__cta {
      background-color: $green;
      color: $white;
    }
  }

  &--split-img {
    height: 300px;

    .banner__img {
      height: 100%;
      width: 100%;
    }
  }

  &--c2a {
    background-color: transparentize($blue, 0.4);

    .heading-accent {
      &::after {
        left: 0;

        @media screen and (max-width: breakpoint-max(sm)) {
          left: 50%;
          transform: translate(-50%, 0);
        }
      }
    }
  }
}