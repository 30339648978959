.btn {
  border-width: 2px;
  border-radius: 0;

  font-family: $font-family-sans-serif;
  letter-spacing: $btn-letter-spacing;
  @include box-shadow(2);
  transition: $transition-timing-fast;

  &:hover {
    box-shadow: 4px 0 4px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12), 0 2px 4px -1px rgba(0, 0, 0, .20);
  }

  &[class^=btn-outline-]{
    background-color: transparent;
    border-width: 2px;

    &--thin {
      border-width: 1px;
    }
  }

  &.btn--go {
    display: inline-block;

    &::after {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      line-height: 2;
      content: "\f061";
      color: $green;
    }
  }
}