main.property-services {
  #services-accordion {
    .card {
      border-width: 1px 0 1px 0;

      .card-header {
        background-color: transparent;
        border-bottom: 0;

        a {
          text-decoration: none;

          i {
            transition: $transition-timing-slow;
          }

          &[aria-expanded=true]{
            i {
              transform: rotate(90deg);
            }
          }
        }
      }

      .card-body {
        img {
          width: 40%;
          height: auto;
          @include box-shadow(2);

          @media screen and (max-width: breakpoint-max(sm)) {
            width: 100%;
          }
        }
      }
    }
  }
}