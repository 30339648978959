.why-image {
  object-fit: cover;
  width: 100%;
  height: 260px;
  @include box-shadow(1);
}

.why-items {
  .why-item {
    background-color: $white;
    border-radius: 5px;
    @include box-shadow(1);
    transition: $transition-timing-normal;

    &__header {
      display: flex;
      align-items: center;

      &-icon {
        width: 32px;
        max-height: 32px;
      }
    }

    &__icon {
      width: 32px;
      height: 32px;
      object-fit: contain;
    }

    &:hover {
      transform: translate(0, -5px) scale(1.0125);
      @include box-shadow(4);
    }
  }
}