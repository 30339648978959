.hero {
  position: relative;
  width: 100%;
  height: 500px;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparentize($black, 0.8);
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 0;
    max-width: 75%;
    transform: translate(0, -50%);

    @media screen and (max-width: breakpoint-max(sm)) {
      max-width: 100%;
    }
  }

  &--slim {
    height: 300px;
  }
}