.footer {
  @include box-shadow(8);

  .footer__item {
    display: flex;

    .footer__item-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 60px;
      height: 60px;
      margin-right: map_get($spacers, 3);
      border-radius: 50%;
    }

    .footer__item-info {
      h5 {
        text-transform: uppercase;
        font-family: $font-family-sans-serif;
      }

      .footer__item-info-address {
        text-decoration: none;

        p {
          margin-bottom: 0;
        }
      }
    }
  }
}

.sub-footer {
  padding: map_get($spacers, 1) 0;
  border-top: 2px solid $gray-300;
  letter-spacing: 0.75px;

  &__policy {
    padding: 0 map_get($spacers, 2);
  }

  .footer__euphoriq {
    img {
      width: auto;
      height: 14px;
    }
  }
}