.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: $blue;
  @include box-shadow(4);
  z-index: $zindex-fixed;

  p, a {
    color: $white;
    margin-bottom: 0;
  }

  a {
    text-decoration: underline;
  }
}