.contact-us {
  @include box-shadow(4);

  @media screen and (min-width: breakpoint-min(md)) {
    &__col:first-of-type {
      border-right: 1px solid transparentize($lightgreen, 0.6);
    }
  }

  &__form {
    .form-control {
      border: 0;
      border-radius: 0;
      background-color: transparentize($lightgreen, 0.85);
    }
  }
}

#location-map {
  height: 400px;
  z-index: -1;
}