.affiliated-brands {
  .affiliated-brand {
    margin-bottom: map_get($spacers, 4);
    padding: 0 map_get($spacers, 3);

    .affiliated-brand__image {
      height: 60px;
      width: auto;
    }
  }
}