.intro-block {
  position: relative;

  &--brand {
    &::after {
      content: "";
      position: absolute;
      height: calc(100% - #{map_get($spacers, 6)});
      width: 60%;
      top: 50%;
      left: 0;
      background-color: transparentize($lightgreen, 0.8);
      transform: translate(0, -50%);
      z-index: -1;

      @media screen and (max-width: breakpoint-max(sm)) {
        display: none;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media screen and (min-width: breakpoint-min(md)) {
      align-items: start;
      text-align: left;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    margin-left: auto;
    object-fit: cover;

    @include box-shadow(2);
  }
}