body {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: $body-offset;

  @media screen and (max-width: breakpoint-max(sm)) {
    padding-top: $body-offset-mobile;
  }

  .body-overlay {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: $zindex-fixed;
    transition: background-color $transition-timing-normal;
  }

  main {
    flex: 1 0 auto;
    overflow: hidden;
    transition: $transition-timing-normal;

    &.blur {
      filter: blur(4px) grayscale(50%);
    }
  }
}

.bg-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  &--fixed {
    background-attachment: fixed;
  }
}

.img-cover {
  object-fit: cover !important;
}

.img-contain {
  object-fit: contain !important;
}


.content-block {
  padding: map_get($spacers, 5) 0;

  @media screen and (max-width: breakpoint-max(sm)) {
    padding: map_get($spacers, 4) 0;
  }

  &--lg {
    padding: map_get($spacers, 6) 0;

    @media screen and (max-width: breakpoint-max(sm)) {
      padding: map_get($spacers, 5) 0;
    }
  }

  &--xl{
    padding: map_get($spacers, 7) 0;

    @media screen and (max-width: breakpoint-max(sm)) {
      padding: map_get($spacers, 5) 0;
    }
  }
}

// Responsive Width and Height properties
@each $prop, $abbrev in (width: w, height: h) {
  @each $bpname,$bpsize in $grid-breakpoints {
    @media (min-width: $bpsize) {
      @each $size, $length in $sizes {
        .#{$abbrev}-#{$bpname}-#{$size} {
          #{$prop}: $length !important;
        }
      }
    }
  }
}