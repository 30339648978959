.section-info-block {
  &:nth-of-type(odd) {
    background-color: $gray;
  }

  &__image {
    width: 100%;
    height: auto;
    @include box-shadow(1);
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    color: $green;
    margin-bottom: map_get($spacers, 4);

    &:not(:first-child){
      color: $gray-600;
      margin-top: map_get($spacers, 5);
    }
  }

  p:last-of-type {
    margin-bottom: 0;
  }
}