h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
p, span {
  &.heading-accents {
    position: relative;
    padding-bottom: 1rem;
    margin-bottom: calc(1rem + 6px);

    &::after, &::before {
      content: "";
      position: absolute;
      left: 0;
      height: 2px;
      background-color: $white;
    }

    &::before {
      bottom: 0;
      width: 2rem;
    }

    &::after {
      bottom: -4px;
      width: 1.5rem;
    }

    &--single {
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 30px;
        height: 2px;
        background-color: $green;
        transform: translate(-50%, 0);
      }
    }
  }

  // TODO: Refactor heading accent classes, using mixin to generate color and alignment variations
  &.heading-accent {
    position: relative;
    padding-bottom: 1rem;
    margin-bottom: calc(1rem + 6px);

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 30px;
      height: 2px;
    }

    &.heading-accent--left {
      &::after {
        left: 0;
      }
    }

    &.heading-accent--center {
      &::after {
        left: 50%;
        transform: translate(-50%, 0);
      }
    }

    &.heading-accent--right {
      &:after {
        right: 0;
      }
    }

    &.heading-accent-right {
      right: 0 !important;
    }

    &--green {
      &::after {
        background-color: $green;
      }
    }

    &--lightgreen {
      &::after {
        background-color: $lightgreen;
      }
    }

    &--white {
      &::after {
        background-color: $white;
      }
    }
  }
}

p {
  color: $gray-600;
}