$nav-primary-color: $green;

.navbar {
  @include box-shadow(16);

  .navbar-brand {
    img {
      height: 50px;

      @media screen and (max-width: breakpoint-max(sm)) {
        height: 40px
      }
    }
  }

  #primary-nav {
    .nav-item {
      &:not(.nav-item--contact) {
        margin: 0 map_get($spacers, 1);
        transition: $transition-timing-fast;

        &:hover {
          background-color: transparentize($nav-primary-color, 0.8);
        }
      }

      &--contact {
        margin-top: 0;
        background-color: $nav-primary-color;
        @include box-shadow(2);
        transition: $transition-timing-fast;

        @media screen and (max-width: breakpoint-max(md)) {
          margin-top: map_get($spacers, 3);
        }

        &:hover {
          @include box-shadow(4);
        }

        .nav-link {
          color: $white;
        }
      }

      .nav-link {
        padding: map_get($spacers, 2) map_get($spacers, 3);
        letter-spacing: $btn-letter-spacing;
      }

      .dropdown-item {
        &.active, &:active {
          background-color: transparentize($green, 0.4);
        }

        &:not(:last-child) {
          border-bottom: 1px solid transparentize($black, 0.9);
        }
      }
    }
  }

  .hamburger {
    height: 24px;
    padding: 0;
    display: inline-block;
    outline: 0 !important;
    cursor: pointer;

    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;

    // Normalize (<button>)
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;

    @media screen and (min-width: breakpoint-min(lg)) {
      display: none;
    }

    &:hover {
      opacity: $hamburger-hover-opacity;
    }

    &.is-active {
      &:hover {
        opacity: $hamburger-active-hover-opacity;
      }

      .hamburger-inner,
      .hamburger-inner::before,
      .hamburger-inner::after {
        background-color: $hamburger-active-layer-color;
      }
    }
  }

  .hamburger-box {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
    display: inline-block;
    position: relative;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: $hamburger-layer-height / -2;

    &,
    &::before,
    &::after {
      width: $hamburger-layer-width;
      height: $hamburger-layer-height;
      background-color: $hamburger-layer-color;
      border-radius: $hamburger-layer-border-radius;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }

    &::before,
    &::after {
      content: "";
      display: block;
    }

    &::before {
      top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }

    &::after {
      bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }
  }


  .hamburger--collapse {
    .hamburger-inner {
      top: auto;
      bottom: 0;
      transition-duration: 0.13s;
      transition-delay: 0.13s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

      &::after {
        top: ($hamburger-layer-spacing * 2 + $hamburger-layer-height * 2) * -1;
        transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        opacity 0.1s linear;
      }

      &::before {
        transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }

    &[aria-expanded=true] {
      .hamburger-inner {
        transform: translate3d(0, ($hamburger-layer-spacing + $hamburger-layer-height) * -1, 0) rotate(-45deg);
        transition-delay: 0.22s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &::after {
          top: 0;
          opacity: 0;
          transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
          opacity 0.1s 0.22s linear;
        }

        &::before {
          top: 0;
          transform: rotate(-90deg);
          transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
          transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }
  }
}