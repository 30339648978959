
// Brand Colors
$green:       #3e6c53;
$lightgreen:  #c8cc92;
$blue:        #779fa1;
$pink:        #edb6a3;
$gray:        #fafafa;
$midgray:     #f7f7f8;
$darkgray:    #e7e7e7;
$white:       #ffffff;


$colors: ();
$colors: map_merge(
  (
    "green"       : $green,
    "lightgreen"  : $lightgreen,
    "blue"        : $blue,
    "pink"        : $pink,
    "white"       : $white,
    "gray"        : $gray,
    "mid-gray"    : $midgray,
    "dark-gray"   : $darkgray
  ),
  $colors
);

$accent:      $blue;

$theme-colors: ();
$theme-colors: map_merge(
  (
    "accent"      : $accent,
    "green"       : $green,
    "lightgreen"  : $lightgreen,
    "blue"        : $blue,
    "pink"        : $pink,
    "white"       : $white,
    "primary"     : $green,
    "secondary"   : $lightgreen,
    "gray"        : $gray,
    "mid-gray"    : $midgray,
    "dark-gray"   : $darkgray
  ),
  $theme-colors
);


$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge((
      0: 0,
      -1: (-$spacer * .25),
      -2: (-$spacer * .5),
      -3: -$spacer,
      -4: (-$spacer * 1.5),
      -5: (-$spacer * 3),
      -6: (-$spacer * 4.5),
      -7: (-$spacer * 6)
), $spacers);



// Navbar hamburger variables. Sourced from https://github.com/jonsuh/hamburgers
$hamburger-padding-x           : 15px !default;
$hamburger-padding-y           : 15px !default;
$hamburger-layer-width         : 40px !default;
$hamburger-layer-height        : 4px !default;
$hamburger-layer-spacing       : 6px !default;
$hamburger-layer-color         : #000 !default;
$hamburger-layer-border-radius : 4px !default;
$hamburger-hover-opacity       : 0.7 !default;
$hamburger-active-layer-color  : $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;


// Body offset due to navbar 'position: fixed'
$body-offset: 76px;
$body-offset-mobile: 66px;


// Transition timings
$transition-timing-slow: 0.45s;
$transition-timing-normal: 0.3s;
$transition-timing-fast: 0.15s;


// Buttons
$btn-letter-spacing: 0.025rem;